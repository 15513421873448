// src/pages/ProjectsPage.jsx
import React from 'react';
import Projects from '../components/projects/Projects';
import AdditionalProjects from '../components/projects/AdditionalProjects';
import { Box, Typography, Container, Divider } from '@mui/material';

const ProjectsPage = () => {
  return (
    <>
      <Box 
        sx={{ 
          py: 6,
          background: 'linear-gradient(rgba(0, 30, 60, 0.9), rgba(0, 30, 60, 0.8))',
          mb: 4
        }}
      >
        <Container maxWidth="lg">
          <Typography variant="h3" component="h1" align="center" color="white" gutterBottom fontWeight="bold">
            Azure Cloud Projects
          </Typography>
          <Typography variant="subtitle1" align="center" color="white" paragraph>
            Showcasing enterprise-level cloud solutions architect experience across various domains
          </Typography>
        </Container>
      </Box>
      
      {/* Featured Projects Section */}
      <Projects />
      
      <Box sx={{ my: 8 }}>
        <Container maxWidth="lg">
          <Divider>
            <Typography variant="h6" color="text.secondary" sx={{ px: 2 }}>
              MORE PROJECTS
            </Typography>
          </Divider>
        </Container>
      </Box>
      
      {/* Additional Projects Section */}
      <AdditionalProjects />
    </>
  );
};

export default ProjectsPage;



// // src/pages/ProjectsPage.jsx
// import React from 'react';
// import Projects from '../components/projects/Projects';
// import { Box, Typography, Container } from '@mui/material';

// const ProjectsPage = () => {
//   return (
//     <>
//       <Box 
//         sx={{ 
//           py: 6,
//           background: 'linear-gradient(rgba(0, 30, 60, 0.9), rgba(0, 30, 60, 0.8))',
//           mb: 4
//         }}
//       >
//         <Container maxWidth="lg">
//           <Typography variant="h3" component="h1" align="center" color="white" gutterBottom fontWeight="bold">
//             Azure Cloud Projects
//           </Typography>
//           <Typography variant="subtitle1" align="center" color="white" paragraph>
//             Showcasing enterprise-level cloud solutions architect experience across various domains
//           </Typography>
//         </Container>
//       </Box>
//       <Projects />
//     </>
//   );
// };

// export default ProjectsPage;