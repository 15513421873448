// src/pages/HomePage.jsx
import React from "react";
import Hero from "../components/home/Hero";
import FeatureProjects from "../components/home/FeatureProjects";
import SkillsHighlight from "../components/home/SkillsHighlight";
import CertificationsHighlight from "../components/home/CertificationsHighlight";
import CallToAction from "../components/home/CallToAction";

const HomePage = () => {
  return (
    <>
      <Hero />
      <SkillsHighlight />
      <FeatureProjects />
      <CertificationsHighlight />
      <CallToAction />
    </>
  );
};

export default HomePage;