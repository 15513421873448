// src/components/home/CallToAction.jsx
import React from 'react';
import { Box, Container, Typography, Button, Paper, Grid } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import DescriptionIcon from '@mui/icons-material/Description';

const CallToAction = () => {
  return (
    <Box 
      sx={{ 
        py: 8, 
        bgcolor: 'primary.dark',
        color: 'white',
        backgroundImage: 'linear-gradient(135deg, rgba(0, 30, 60, 0.9), rgba(0, 30, 60, 0.8))',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={7}>
            <Typography variant="h4" component="h2" gutterBottom fontWeight="bold">
              Ready to Elevate Your Azure Cloud Journey?
            </Typography>
            <Typography variant="subtitle1" paragraph sx={{ mb: 4, opacity: 0.9 }}>
              Let's collaborate on your next cloud project. With expertise in Azure architecture, security, and DevOps, 
              I can help you design, implement, and optimize solutions that drive business value.
            </Typography>
            
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
              <Button 
                component={RouterLink} 
                to="/contact" 
                variant="contained" 
                size="large"
                startIcon={<EmailIcon />}
                sx={{ 
                  bgcolor: 'white', 
                  color: 'primary.dark',
                  '&:hover': {
                    bgcolor: 'rgba(255, 255, 255, 0.9)',
                  }
                }}
              >
                Get In Touch
              </Button>
              <Button 
                component="a" 
                href="/Leonard AEsere.pdf" 
                target="_blank"
                rel="noopener noreferrer"
                download
                variant="outlined" 
                size="large"
                startIcon={<DescriptionIcon />}
                sx={{ 
                  borderColor: 'white', 
                  color: 'white',
                  '&:hover': {
                    borderColor: 'white',
                    bgcolor: 'rgba(255, 255, 255, 0.1)',
                  }
                }}
              >
                Download Resume
              </Button>
            </Box>
          </Grid>
          
          <Grid item xs={12} md={5}>
            <Paper 
              elevation={0}
              sx={{ 
                p: 3,
                bgcolor: 'rgba(255, 255, 255, 0.1)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255, 255, 255, 0.2)',
                borderRadius: 2
              }}
            >
              <Typography variant="h6" gutterBottom fontWeight="bold">
                Key Areas of Expertise
              </Typography>
              
              <Box component="ul" sx={{ pl: 2 }}>
                <Box component="li" sx={{ mb: 1 }}>
                  <Typography>
                    Azure Cloud Architecture & Migration
                  </Typography>
                </Box>
                <Box component="li" sx={{ mb: 1 }}>
                  <Typography>
                    Security & Compliance in Azure
                  </Typography>
                </Box>
                <Box component="li" sx={{ mb: 1 }}>
                  <Typography>
                    DevOps & Infrastructure as Code
                  </Typography>
                </Box>
                <Box component="li" sx={{ mb: 1 }}>
                  <Typography>
                    Kubernetes & Containerization
                  </Typography>
                </Box>
                <Box component="li">
                  <Typography>
                    Hybrid Cloud Solutions
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CallToAction;