// src/components/home/FeatureProjects.jsx
import React from 'react';
import { Box, Container, Typography, Grid, Card, CardContent, Button, Chip, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import getProjectsData from '../../data/projectsData';

const FeatureProjects = () => {
  // Get top 3 featured projects
  const featuredProjects = getProjectsData().slice(0, 3);
  
  return (
    <Box sx={{ py: 6, bgcolor: 'background.default' }}>
      <Container maxWidth="lg">
        <Box sx={{ mb: 5, textAlign: 'center' }}>
          <Typography variant="h4" component="h2" gutterBottom fontWeight="bold">
            Featured Projects
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" paragraph sx={{ maxWidth: '700px', mx: 'auto' }}>
            A selection of my recent work in cloud architecture, security, and DevOps
          </Typography>
        </Box>
        
        <Grid container spacing={4}>
          {featuredProjects.map((project) => (
            <Grid item xs={12} md={4} key={project.id}>
              <Card 
                sx={{ 
                  height: '100%', 
                  display: 'flex', 
                  flexDirection: 'column',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 10px 20px rgba(0,0,0,0.1)'
                  }
                }}
              >
                <Box 
                  sx={{ 
                    height: 12, 
                    bgcolor: project.color || 'primary.main' 
                  }}
                />
                <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6" component="h3" gutterBottom>
                    {project.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" paragraph sx={{ mb: 3 }}>
                    {project.shortDescription}
                  </Typography>
                  
                  <Stack 
                    direction="row" 
                    spacing={1} 
                    sx={{ 
                      flexWrap: 'wrap', 
                      gap: 0.5,
                      mb: 3,
                      mt: 'auto'
                    }}
                  >
                    {project.technologies.slice(0, 3).map((tech, index) => (
                      <Chip 
                        key={index} 
                        label={tech} 
                        size="small" 
                        sx={{ 
                          mt: 0.5,
                          bgcolor: 'background.paper', 
                          border: '1px solid', 
                          borderColor: 'divider'
                        }}
                      />
                    ))}
                    {project.technologies.length > 3 && (
                      <Chip 
                        label={`+${project.technologies.length - 3}`} 
                        size="small" 
                        sx={{ mt: 0.5 }}
                      />
                    )}
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button 
            component={RouterLink} 
            to="/projects" 
            endIcon={<ArrowForwardIcon />}
            variant="outlined"
            size="large"
            sx={{ px: 3 }}
          >
            View All Projects
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default FeatureProjects;