// src/pages/CertificationsPage.jsx
import React from 'react';
import Certifications from '../components/certifications/Certifications';
import { Box, Typography, Container } from '@mui/material';

const CertificationsPage = () => {
  return (
    <>
      <Box 
        sx={{ 
          py: 6,
          background: 'linear-gradient(rgba(0, 30, 60, 0.9), rgba(0, 30, 60, 0.8))',
          mb: 4
        }}
      >
        <Container maxWidth="lg">
          <Typography variant="h3" component="h1" align="center" color="white" gutterBottom fontWeight="bold">
            Microsoft Azure Certifications
          </Typography>
          <Typography variant="subtitle1" align="center" color="white" paragraph>
            Expert-level credentials validating deep technical knowledge and hands-on experience
          </Typography>
        </Container>
      </Box>
      <Certifications />
    </>
  );
};

export default CertificationsPage;
