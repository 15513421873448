// src/pages/ContactPage.jsx
import React, { useState } from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  Grid, 
  Paper, 
  TextField, 
  Button, 
  Snackbar, 
  Alert, 
  Divider,
  Card,
  CardContent,
  IconButton,
  Tooltip
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import CodeIcon from '@mui/icons-material/Code'; // For freeCodeCamp
import SchoolIcon from '@mui/icons-material/School'; // For Microsoft Learn
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const ContactPage = () => {
  // Form state
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  
  // Form validation state
  const [errors, setErrors] = useState({});
  
  // Submission state
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  
  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    
    // Clear error when field is being edited
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null
      });
    }
  };
  
  // Validate form
  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }
    
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    
    if (!formData.subject.trim()) {
      newErrors.subject = 'Subject is required';
    }
    
    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
    } else if (formData.message.trim().length < 10) {
      newErrors.message = 'Message should be at least 10 characters';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    setSubmitting(true);
    
    try {
      // In a real implementation, you would send this data to your backend
      // For this example, we'll simulate a successful submission
      
      // This is where you would integrate with a backend service
      // that handles sending emails to lesere@laeintel.com
      
      // Example of how the backend endpoint might be called:
      // await fetch('/api/send-email', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({
      //     ...formData,
      //     recipientEmail: 'lesere@laeintel.com'
      //   }),
      // });
      
      // Simulating API call with timeout
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Clear form on success
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
      
      setSubmitSuccess(true);
    } catch (error) {
      console.error('Error sending message:', error);
      setSubmitError(true);
    } finally {
      setSubmitting(false);
    }
  };
  
  // Handle snackbar close
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSubmitSuccess(false);
    setSubmitError(false);
  };

  return (
    <>
      <Box 
        sx={{ 
          py: 6,
          background: 'linear-gradient(rgba(0, 30, 60, 0.9), rgba(0, 30, 60, 0.8))',
          mb: 4
        }}
      >
        <Container maxWidth="lg">
          <Typography variant="h3" component="h1" align="center" color="white" gutterBottom fontWeight="bold">
            Get In Touch
          </Typography>
          <Typography variant="subtitle1" align="center" color="white" paragraph>
            Let's discuss how we can work together on your next cloud project
          </Typography>
        </Container>
      </Box>
    
      <Container maxWidth="lg" sx={{ mb: 8 }}>
        <Grid container spacing={4}>
          {/* Contact Form */}
          <Grid item xs={12} md={8}>
            <Paper
              elevation={0}
              variant="outlined"
              sx={{
                p: 4,
                borderRadius: 2
              }}
            >
              <Typography variant="h5" component="h2" gutterBottom fontWeight="bold">
                Send Me a Message
              </Typography>
              <Typography variant="body2" color="text.secondary" paragraph>
                Feel free to reach out with questions, project inquiries, or just to connect!
              </Typography>
              
              <Divider sx={{ my: 3 }} />
              
              <Box component="form" onSubmit={handleSubmit} noValidate>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      required
                      label="Your Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      error={!!errors.name}
                      helperText={errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      required
                      label="Email Address"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      error={!!errors.email}
                      helperText={errors.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      label="Subject"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      error={!!errors.subject}
                      helperText={errors.subject}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      multiline
                      rows={6}
                      label="Your Message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      error={!!errors.message}
                      helperText={errors.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      disabled={submitting}
                      endIcon={<SendIcon />}
                      sx={{ px: 4 }}
                    >
                      {submitting ? 'Sending...' : 'Send Message'}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
          
          {/* Contact Information */}
          <Grid item xs={12} md={4}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              {/* Contact Info Card */}
              <Card 
                elevation={0} 
                variant="outlined" 
                sx={{ borderRadius: 2 }}
              >
                <CardContent>
                  <Typography variant="h5" component="h3" gutterBottom fontWeight="bold">
                    Contact Information
                  </Typography>
                  
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <EmailIcon sx={{ mr: 1, color: 'primary.main' }} />
                      Email
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      lesere@laeintel.com
                    </Typography>
                  </Box>
                  
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <PhoneIcon sx={{ mr: 1, color: 'primary.main' }} />
                      Phone
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Available upon request
                    </Typography>
                  </Box>
                  
                  <Box>
                    <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <LocationOnIcon sx={{ mr: 1, color: 'primary.main' }} />
                      Location
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Texas, United States
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
              
              {/* Connect Card */}
              <Card 
                elevation={0} 
                variant="outlined" 
                sx={{ borderRadius: 2 }}
              >
                <CardContent>
                  <Typography variant="h5" component="h3" gutterBottom fontWeight="bold">
                    Let's Connect
                  </Typography>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    Connect with me on professional networks and explore my code repositories.
                  </Typography>
                  
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
                    <Tooltip title="LinkedIn">
                      <IconButton 
                        color="primary" 
                        aria-label="LinkedIn profile" 
                        component="a" 
                        href="https://www.linkedin.com/in/leonardesere/" 
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LinkedInIcon fontSize="large" />
                      </IconButton>
                    </Tooltip>
                    
                    <Tooltip title="GitHub">
                      <IconButton 
                        color="primary" 
                        aria-label="GitHub profile" 
                        component="a" 
                        href="https://github.com/NijTeck" 
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <GitHubIcon fontSize="large" />
                      </IconButton>
                    </Tooltip>
                    
                    <Tooltip title="freeCodeCamp">
                      <IconButton 
                        color="primary" 
                        aria-label="freeCodeCamp profile" 
                        component="a" 
                        href="https://www.freecodecamp.org/regedited" 
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CodeIcon fontSize="large" />
                      </IconButton>
                    </Tooltip>
                    
                    <Tooltip title="Microsoft Learn">
                      <IconButton 
                        color="primary" 
                        aria-label="Microsoft Learn profile" 
                        component="a" 
                        href="https://learn.microsoft.com/en-us/users/leonardesere/" 
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <SchoolIcon fontSize="large" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </CardContent>
              </Card>
              
              {/* Availability Card */}
              <Card 
                elevation={0} 
                variant="outlined" 
                sx={{ 
                  borderRadius: 2,
                  bgcolor: 'primary.dark',
                  color: 'white'
                }}
              >
                <CardContent>
                  <Typography variant="h6" component="h3" gutterBottom fontWeight="bold">
                    Current Availability
                  </Typography>
                  <Typography variant="body2" paragraph>
                    I'm currently available for new Azure architecture and cloud engineering projects. My typical response time is within 24 hours.
                  </Typography>
                  <Typography variant="subtitle2">
                    Open to both contract and full-time opportunities.
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
      
      {/* Success/Error notifications */}
      <Snackbar open={submitSuccess} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Your message has been sent successfully! I'll get back to you soon.
        </Alert>
      </Snackbar>
      
      <Snackbar open={submitError} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
          There was an error sending your message. Please try again later.
        </Alert>
      </Snackbar>
    </>
  );
};

export default ContactPage;