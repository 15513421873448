// src/data/projectsData.jsx
import React from 'react';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import EngineeringIcon from '@mui/icons-material/Engineering';
import SecurityIcon from '@mui/icons-material/Security';
import StorageIcon from '@mui/icons-material/Storage';
import CloudIcon from '@mui/icons-material/Cloud';
import DevicesIcon from '@mui/icons-material/Devices';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';

// Create a separate file for project data
const getProjectsData = () => [
    {
        id: 1,
        title: "Multi-Region Disaster Recovery Solution",
        shortDescription: "Designed and implemented a geo-redundant DR solution for a critical financial application with 99.99% uptime SLA requirements.",
        challenge: "A financial services client needed a robust disaster recovery solution for their mission-critical transaction processing system that could guarantee near-zero data loss and rapid recovery in case of regional outages.",
        solution: "Architected a comprehensive multi-region disaster recovery solution utilizing Azure's geo-replication capabilities, Traffic Manager for intelligent routing, and automated failover procedures to ensure business continuity.",
        technologies: ["Azure Traffic Manager", "Azure SQL Database", "Geo-Replication", "Azure Site Recovery", "Azure Automation", "Azure Monitor", "ARM Templates"],
        category: "architecture",
        color: "#0078d4",
        icon: <ArchitectureIcon sx={{ fontSize: 60, color: "white" }} />,
        architecture: {
            description: "The solution leverages a primary and secondary region architecture with active-passive configuration. All data is continuously replicated between regions, and automated health checks monitor system availability.",
            keyElements: [
                "Active-passive configuration with Azure Traffic Manager for automatic failover",
                "Geo-replicated Azure SQL Database with near-zero RPO",
                "Automated recovery scripts using Azure Automation runbooks",
                "Comprehensive monitoring and alerting via Azure Monitor",
                "Regular DR testing with documented procedures"
            ],
            services: ["Azure Traffic Manager", "Azure SQL Database", "Azure Site Recovery", "Azure VMs", "Azure Automation", "Azure Monitor"]
        },
        implementation: {
            description: "Implemented the DR solution using Infrastructure as Code for consistent deployment across environments and automated testing procedures to validate recovery capabilities.",
            steps: [
                {
                    title: "Infrastructure Deployment",
                    description: "Used ARM templates to create identical infrastructure in primary and secondary regions"
                },
                {
                    title: "Data Replication Configuration",
                    description: "Set up geo-replication for Azure SQL Database with 5-second RPO"
                },
                {
                    title: "Network Configuration",
                    description: "Configured Azure Traffic Manager with priority routing and health probes"
                },
                {
                    title: "Automation Development",
                    description: "Created Azure Automation runbooks for automated failover procedures"
                },
                {
                    title: "Monitoring Setup",
                    description: "Implemented comprehensive monitoring and alerting with Azure Monitor"
                }
            ],
            challenges: "Overcame challenges with maintaining transactional consistency during failover by implementing custom middleware caching and retry logic. Addressed network latency issues by optimizing database connection patterns and implementing connection pooling."
        },
        results: {
            description: "The solution successfully provided the client with a robust disaster recovery capability that met their strict business continuity requirements.",
            metrics: [
                "Achieved 99.995% system availability over the past 12 months",
                "Reduced Recovery Time Objective (RTO) from 4 hours to under 15 minutes",
                "Decreased Recovery Point Objective (RPO) from 1 hour to under 10 seconds",
                "Successfully executed 12 planned failover tests with zero issues",
                "Documented 30% improvement in system resilience metrics"
            ],
            successFactors: "The project's success was driven by thorough architecture planning, comprehensive testing methodology, and close alignment with business requirements. The automated nature of the solution removed human error from the recovery process."
        }
    },

    {
        id: 2,
        title: "Secure Cloud Data Platform",
        shortDescription: "Built a highly secure data analytics platform for a healthcare provider using Azure Synapse Analytics and Azure Purview.",
        challenge: "A healthcare organization needed to consolidate disparate data sources into a unified analytics platform while maintaining strict compliance with HIPAA and ensuring robust security for sensitive patient information.",
        solution: "Designed and implemented a comprehensive data platform using Azure Synapse Analytics with advanced security controls, data governance through Azure Purview, and end-to-end encryption to protect sensitive healthcare data.",
        technologies: ["Azure Synapse Analytics", "Azure Purview", "Azure Key Vault", "Azure Private Link", "Azure RBAC", "Azure Active Directory", "Data Factory"],
        category: "security",
        color: "#d13438",
        icon: <SecurityIcon sx={{ fontSize: 60, color: "white" }} />,
        architecture: {
            description: "The architecture centers around Azure Synapse Analytics as the unified analytics engine, with multiple security layers including network isolation, encryption, access controls, and comprehensive data governance.",
            keyElements: [
                "Network isolation using Azure Private Link for all PaaS services",
                "End-to-end encryption for data at rest and in transit",
                "Fine-grained access control with Azure RBAC and column-level security",
                "Automated data classification and masking of PHI",
                "Comprehensive audit logging and monitoring"
            ],
            services: ["Azure Synapse Analytics", "Azure Purview", "Azure Key Vault", "Azure Private Link", "Azure Data Factory", "Azure Monitor", "Azure Sentinel"]
        },
        implementation: {
            description: "Implementation focused on security-first approaches, with multiple layers of protection and compliance validation at each step.",
            steps: [
                {
                    title: "Secure Network Design",
                    description: "Implemented private endpoints for all Azure services with no public internet exposure"
                },
                {
                    title: "Data Ingestion Pipeline",
                    description: "Created secure ETL processes using Azure Data Factory with managed identity authentication"
                },
                {
                    title: "Security Controls",
                    description: "Implemented column-level security, dynamic data masking, and row-level security in Synapse"
                },
                {
                    title: "Data Governance",
                    description: "Deployed Azure Purview for automated data classification, cataloging, and lineage tracking"
                },
                {
                    title: "Monitoring & Compliance",
                    description: "Set up Azure Sentinel for security monitoring and threat detection"
                }
            ],
            challenges: "Overcame challenges with maintaining performance while implementing multiple security layers by optimizing query patterns and implementing materialized views. Addressed complex compliance requirements by creating a comprehensive security control matrix mapped to HIPAA requirements."
        },
        results: {
            description: "The solution delivered a highly secure analytics platform that passed all security audits while providing valuable insights from previously siloed data.",
            metrics: [
                "Successfully passed external HIPAA compliance audit with zero findings",
                "Consolidated data from 12 disparate systems into a unified platform",
                "Implemented 50+ security controls mapped to compliance requirements",
                "Reduced time to generate compliance reports from days to minutes",
                "Enabled secure self-service analytics for 200+ clinical staff"
            ],
            successFactors: "Success was driven by the defense-in-depth security approach, comprehensive governance implementation, and close collaboration with compliance teams throughout the development process."
        }
    },
    {
        id: 3,
        title: "Enterprise Azure DevOps Pipeline Implementation",
        shortDescription: "Designed and implemented a comprehensive CI/CD ecosystem for a global enterprise, enabling rapid and secure deployment across 100+ applications.",
        challenge: "A multinational corporation with 100+ application teams needed to standardize their deployment processes, improve security, and accelerate time-to-market while maintaining governance and compliance requirements.",
        solution: "Implemented an enterprise-wide DevOps strategy with standardized CI/CD pipelines in Azure DevOps, including security scanning, automated testing, and governance controls.",
        technologies: ["Azure DevOps", "Azure Kubernetes Service", "Terraform", "Azure Policy", "Azure Key Vault", "Azure Container Registry", "SonarQube"],
        category: "devops",
        color: "#7FBA00",
        icon: <EngineeringIcon sx={{ fontSize: 60, color: "white" }} />,
        architecture: {
            description: "The architecture provides a standardized yet flexible CI/CD platform using Azure DevOps with built-in security, governance, and quality controls.",
            keyElements: [
                "Template-based pipeline approach with inheritance for governance",
                "Security scanning integration at multiple stages (static code analysis, container scanning, dependency checks)",
                "Infrastructure as Code using Terraform with automated validation",
                "Automated testing framework with consistent quality gates",
                "Approval workflows and audit logging for change control"
            ],
            services: ["Azure DevOps", "Azure Kubernetes Service", "Azure Container Registry", "Azure Key Vault", "Azure Policy", "Azure Monitor"]
        },
        implementation: {
            description: "The implementation focused on creating reusable pipeline components, security integration at each stage, and comprehensive documentation and training.",
            steps: [
                {
                    title: "Pipeline Template Creation",
                    description: "Developed standardized YAML pipeline templates with built-in security controls"
                },
                {
                    title: "Security Integration",
                    description: "Integrated security scanning tools for code, containers, and infrastructure"
                },
                {
                    title: "Infrastructure Automation",
                    description: "Implemented Terraform modules for consistent infrastructure provisioning"
                },
                {
                    title: "Quality Gates",
                    description: "Established automated testing frameworks and quality metrics"
                },
                {
                    title: "Governance Framework",
                    description: "Created approval workflows and audit mechanisms for compliance"
                }
            ],
            challenges: "Overcame resistance to standardization by developing a flexible framework that allowed teams to customize within governance boundaries. Addressed security concerns by implementing shift-left security practices with automated scanning and feedback loops."
        },
        results: {
            description: "The enterprise DevOps implementation transformed the organization's delivery capabilities while maintaining security and governance standards.",
            metrics: [
                "Reduced average deployment time from days to under 30 minutes",
                "Increased deployment frequency by 300% across application teams",
                "Decreased security vulnerabilities by 78% through automated scanning",
                "Standardized deployment practices across 15 business units",
                "Reduced production incidents by 65% through improved quality gates"
            ],
            successFactors: "The project succeeded by focusing on developer experience while maintaining governance, providing extensive documentation and training, and demonstrating clear business value through measured improvements in delivery metrics."
        }
    },
    {
        id: 4,
        title: "AI-Powered Document Processing Solution",
        shortDescription: "Developed an intelligent document processing system using Azure Cognitive Services and custom ML models to automate data extraction from unstructured documents.",
        challenge: "A financial services organization needed to process thousands of complex financial documents daily, extracting specific data points with high accuracy while reducing manual processing time and errors.",
        solution: "Created an AI-powered document processing pipeline using Azure Cognitive Services, custom ML models trained on financial documents, and an orchestrated workflow system for validation and exception handling.",
        technologies: ["Azure Form Recognizer", "Azure Functions", "Azure Logic Apps", "Azure Cognitive Search", "Custom Vision", "Azure Machine Learning", "Cosmos DB"],
        category: "ai",
        color: "#7719aa",
        icon: <StorageIcon sx={{ fontSize: 60, color: "white" }} />,
        architecture: {
            description: "The solution uses a serverless architecture that ingests documents, processes them through multiple AI services, extracts structured data, and provides a workflow for human verification of uncertain results.",
            keyElements: [
                "Serverless document ingestion using Azure Functions",
                "Multi-stage AI processing pipeline for different document types",
                "Custom ML models for specialized financial document formats",
                "Confidence scoring system for routing to human review",
                "Feedback loop for continuous model improvement"
            ],
            services: ["Azure Form Recognizer", "Azure Functions", "Azure Logic Apps", "Azure Cognitive Search", "Azure Machine Learning", "Cosmos DB", "Azure Storage"]
        },
        implementation: {
            description: "The implementation involved developing custom models, creating an orchestrated processing pipeline, and building feedback mechanisms for continuous improvement.",
            steps: [
                {
                    title: "Document Analysis",
                    description: "Analyzed document types and created extraction requirements for each format"
                },
                {
                    title: "Model Development",
                    description: "Trained custom Form Recognizer models on financial document samples"
                },
                {
                    title: "Processing Pipeline",
                    description: "Built serverless processing workflow using Azure Functions and Logic Apps"
                },
                {
                    title: "Validation System",
                    description: "Created human-in-the-loop validation for low-confidence extractions"
                },
                {
                    title: "Integration",
                    description: "Connected with downstream systems using secure API integration"
                }
            ],
            challenges: "Overcame challenges with document variability by implementing a classification step before extraction and developing specialized models for each document type. Addressed accuracy concerns by creating a confidence scoring system and human review workflow for uncertain extractions."
        },
        results: {
            description: "The AI-powered solution transformed the document processing workflow, significantly reducing manual effort while improving accuracy and throughput.",
            metrics: [
                "Reduced document processing time from hours to minutes",
                "Achieved 92% straight-through processing rate with no human intervention",
                "Improved data extraction accuracy from 85% to 98%",
                "Processed over 10,000 documents daily with consistent performance",
                "Realized 70% cost reduction in document processing operations"
            ],
            successFactors: "Success was achieved through iterative model improvement, careful workflow design that balanced automation with human oversight, and extensive testing with diverse document samples."
        }
    },
    {
        id: 5,
        title: "Secure Hybrid Cloud Infrastructure",
        shortDescription: "Designed and implemented a secure hybrid cloud infrastructure connecting on-premises datacenters with Azure using Zero Trust principles.",
        challenge: "A regulated industry client needed to extend their datacenter capabilities to the cloud while maintaining strict security controls, regulatory compliance, and consistent operations across hybrid environments.",
        solution: "Architected a comprehensive hybrid cloud platform using Azure ExpressRoute, Azure Firewall, Private Link, and advanced identity management to create a secure, compliant environment based on Zero Trust principles.",
        technologies: ["Azure ExpressRoute", "Azure Firewall", "Azure Private Link", "Azure Active Directory", "Azure Security Center", "Azure Sentinel", "Azure Virtual WAN"],
        category: "security",
        color: "#0078d4",
        icon: <CloudIcon sx={{ fontSize: 60, color: "white" }} />,
        architecture: {
            description: "The architecture implements a secure hybrid network fabric with multiple security layers, centralized management, and comprehensive monitoring based on Zero Trust principles.",
            keyElements: [
                "Dedicated ExpressRoute connection with redundant circuits",
                "Hub-and-spoke network topology with centralized security services",
                "Azure Firewall for east-west and north-south traffic inspection",
                "Private Link for secure PaaS service connectivity",
                "Comprehensive identity management with conditional access",
                "Security monitoring with Azure Sentinel"
            ],
            services: ["Azure ExpressRoute", "Azure Virtual Network", "Azure Firewall", "Azure Private Link", "Azure Active Directory", "Azure Security Center", "Azure Sentinel"]
        },
        implementation: {
            description: "The implementation followed a phased approach, establishing the secure network foundation before gradually migrating workloads to the cloud environment.",
            steps: [
                {
                    title: "Network Foundation",
                    description: "Deployed ExpressRoute circuits and hub-and-spoke network architecture"
                },
                {
                    title: "Security Services",
                    description: "Implemented Azure Firewall, NSGs, and Private Link services"
                },
                {
                    title: "Identity Integration",
                    description: "Configured Azure AD with hybrid identity and conditional access policies"
                },
                {
                    title: "Monitoring Setup",
                    description: "Deployed Azure Sentinel with custom detection rules for the environment"
                },
                {
                    title: "Workload Migration",
                    description: "Migrated workloads using secure patterns and landing zones"
                }
            ],
            challenges: "Overcame complex routing challenges by implementing detailed traffic flow analysis and custom routing tables. Addressed compliance concerns by creating a comprehensive security control framework mapped to regulatory requirements with continuous compliance monitoring."
        },
        results: {
            description: "The hybrid cloud infrastructure provided the client with secure cloud capabilities while maintaining their security posture and compliance requirements.",
            metrics: [
                "Successfully passed regulatory audit with zero findings",
                "Maintained consistent security controls across hybrid environment",
                "Reduced security incident response time from days to hours",
                "Achieved 99.99% availability for hybrid connectivity",
                "Enabled 40% faster deployment of new services through secure landing zones"
            ],
            successFactors: "The project succeeded through comprehensive security architecture planning, detailed compliance mapping, and an iterative implementation approach that validated security at each stage."
        }
    },
    {
        id: 6,
        title: "Cloud-Native Microservices Platform",
        shortDescription: "Designed and implemented a cloud-native microservices platform on Azure Kubernetes Service for a digital transformation initiative.",
        challenge: "A retail organization needed to modernize their monolithic e-commerce platform to improve scalability, enable faster feature delivery, and handle seasonal traffic spikes without performance degradation.",
        solution: "Architected a cloud-native microservices platform using Azure Kubernetes Service, implementing event-driven architecture patterns, and establishing a DevOps pipeline for continuous delivery.",
        technologies: ["Azure Kubernetes Service", "Azure Container Registry", "Event Grid", "Service Bus", "Cosmos DB", "Redis Cache", "Application Insights"],
        category: "architecture",
        color: "#7FBA00",
        icon: <DevicesIcon sx={{ fontSize: 60, color: "white" }} />,
        architecture: {
            description: "The architecture uses a decomposed microservices approach with event-driven communication patterns, containerized services, and polyglot persistence suited to each service's needs.",
            keyElements: [
                "Domain-driven microservices design with bounded contexts",
                "Event-driven architecture using Azure Event Grid and Service Bus",
                "Auto-scaling Kubernetes clusters with node pools optimized for workload types",
                "Distributed caching with Redis for performance optimization",
                "API Management for unified API gateway and developer portal"
            ],
            services: ["Azure Kubernetes Service", "Azure Container Registry", "Event Grid", "Service Bus", "Cosmos DB", "Redis Cache", "API Management", "Application Insights"]
        },
        implementation: {
            description: "The implementation followed a phased approach, starting with core services and incrementally decomposing the monolith while maintaining business continuity.",
            steps: [
                {
                    title: "Domain Analysis",
                    description: "Conducted domain-driven design workshops to identify service boundaries"
                },
                {
                    title: "Platform Foundation",
                    description: "Deployed AKS clusters with secure configuration and operational tooling"
                },
                {
                    title: "CI/CD Pipeline",
                    description: "Established container-based CI/CD pipelines for all microservices"
                },
                {
                    title: "Service Migration",
                    description: "Incrementally decomposed monolith into microservices with strangler pattern"
                },
                {
                    title: "Monitoring Implementation",
                    description: "Implemented distributed tracing and comprehensive monitoring"
                }
            ],
            challenges: "Overcome challenges with data consistency by implementing the Saga pattern for distributed transactions. Addressed service discovery and communication issues by leveraging Kubernetes native features and implementing a service mesh for advanced traffic management."
        },
        results: {
            description: "The microservices platform transformed the organization's technical capabilities and enabled rapid business innovation.",
            metrics: [
                "Increased deployment frequency from monthly to daily releases",
                "Reduced page load times by 65% through optimized architecture",
                "Successfully handled 400% traffic increase during sales events",
                "Enabled independent scaling of services, reducing infrastructure costs by 30%",
                "Improved developer productivity with 70% faster development cycles"
            ],
            successFactors: "Success was achieved through strong architecture governance, comprehensive DevOps practices, and focusing on business capabilities rather than technology alone in the design process."
        }
    },
    // Additional projects to add to your projectsData.jsx
    // Add these to the array of projects in getProjectsData()

    {
        id: 7,
        title: "Legacy E-commerce Migration to Azure",
        shortDescription: "Architected and executed the seamless migration of a monolithic e-commerce platform to Azure, resulting in 30% cost reduction and significant performance improvements.",
        challenge: "A traditional retail organization needed to migrate their business-critical legacy e-commerce platform to the cloud while maintaining operations and improving scalability and performance.",
        solution: "Designed a phased hybrid architecture approach utilizing Azure Virtual Machines for lift-and-shift of critical components, Azure App Service for customer-facing applications, and Azure SQL Database for replacing legacy databases.",
        technologies: ["Azure Virtual Machines", "Azure App Service", "Azure SQL Database", "Azure VPN Gateway", "ARM Templates", "Azure DevOps"],
        category: "migration",
        color: "#0078d4",
        icon: <CloudIcon sx={{ fontSize: 60, color: "white" }} />,
        architecture: {
            description: "The solution leveraged a hybrid architecture with secure connectivity between on-premises and Azure environments to enable phased migration with minimal disruption.",
            keyElements: [
                "Azure Virtual Machines for lift-and-shift of critical components",
                "Azure App Service for modernizing customer-facing web applications",
                "Azure SQL Database with geo-replication for high availability",
                "Azure VPN Gateway for secure hybrid connectivity",
                "Infrastructure as Code using ARM templates"
            ],
            services: ["Azure Virtual Machines", "Azure App Service", "Azure SQL Database", "Azure VPN Gateway", "Azure DevOps"]
        },
        implementation: {
            description: "Implemented a phased migration approach with comprehensive assessment, planning, and execution to ensure business continuity throughout the transition.",
            steps: [
                {
                    title: "Infrastructure Assessment",
                    description: "Conducted comprehensive assessment of existing infrastructure and application dependencies"
                },
                {
                    title: "Hybrid Network Setup",
                    description: "Established secure connectivity with Azure VPN Gateway for seamless communication"
                },
                {
                    title: "Database Migration",
                    description: "Migrated from on-premises database to Azure SQL Database with minimal downtime"
                },
                {
                    title: "Application Modernization",
                    description: "Deployed customer-facing components to Azure App Service for improved scalability"
                },
                {
                    title: "Automation Implementation",
                    description: "Created ARM templates and CI/CD pipelines for consistent deployments"
                }
            ],
            challenges: "Overcame complex dependencies between application components by implementing a detailed migration sequencing plan. Addressed data migration challenges by utilizing database replication and implementing a cut-over strategy with minimal downtime."
        },
        results: {
            description: "The migration enabled the organization to decommission their aging on-premises infrastructure while improving application performance and reducing operational costs.",
            metrics: [
                "Reduced infrastructure costs by 30% through optimized Azure resource utilization",
                "Improved application response times by 40% using Azure's global network",
                "Implemented auto-scaling capabilities previously impossible with on-premises infrastructure",
                "Decreased time-to-market for new features from months to weeks",
                "Completed the entire migration with zero unplanned downtime"
            ],
            successFactors: "Success was achieved through careful planning, a phased migration approach, and continuous stakeholder communication throughout the project lifecycle."
        }
    },
    {
        id: 8,
        title: "Serverless Data Processing Pipeline",
        shortDescription: "Built a serverless real-time data processing architecture for IoT devices using Azure Functions and Event Hubs, enabling cost-effective and scalable data analytics.",
        challenge: "An organization with thousands of IoT devices needed a scalable, cost-effective solution to process and analyze real-time data streams without managing complex infrastructure.",
        solution: "Designed and implemented a serverless architecture leveraging Azure Event Hubs for data ingestion, Azure Functions for processing, and Cosmos DB for storage, creating an event-driven pipeline that scales automatically with demand.",
        technologies: ["Azure Functions", "Azure Event Hubs", "Azure Cosmos DB", "Azure Monitor", "Python", "Event-driven Architecture"],
        category: "serverless",
        color: "#7719aa",
        icon: <AutoGraphIcon sx={{ fontSize: 60, color: "white" }} />,
        architecture: {
            description: "The solution uses a fully serverless architecture that automatically scales with incoming data volume, processes events in real-time, and stores results for downstream analysis.",
            keyElements: [
                "Azure Event Hubs for high-throughput real-time data ingestion",
                "Azure Functions to process and transform data streams",
                "Azure Cosmos DB for low-latency storage of processed data",
                "Event-driven architecture for immediate responsiveness",
                "Pay-per-execution model for optimal cost efficiency"
            ],
            services: ["Azure Functions", "Azure Event Hubs", "Azure Cosmos DB", "Azure Monitor", "Azure Stream Analytics"]
        },
        implementation: {
            description: "Implemented a cloud-native, event-driven pipeline that processes data streams in real-time with automatic scaling based on input volume.",
            steps: [
                {
                    title: "Event Hub Configuration",
                    description: "Configured Event Hubs with appropriate partitioning for high-throughput ingestion"
                },
                {
                    title: "Function Development",
                    description: "Developed Azure Functions in Python to process and transform data streams"
                },
                {
                    title: "Database Integration",
                    description: "Integrated with Cosmos DB for scalable, low-latency data storage"
                },
                {
                    title: "Monitoring Setup",
                    description: "Implemented comprehensive monitoring with Azure Monitor and Application Insights"
                },
                {
                    title: "Alert Configuration",
                    description: "Configured automated alerts for anomalies and performance issues"
                }
            ],
            challenges: "Overcame challenges with data consistency by implementing idempotent processing functions. Addressed performance bottlenecks by optimizing Event Hub partitioning strategy and Cosmos DB request units allocation."
        },
        results: {
            description: "The serverless architecture delivered a highly scalable and cost-effective solution that automatically handles varying data volumes without infrastructure management.",
            metrics: [
                "Processed over 10 million events daily with sub-second latency",
                "Achieved 99.99% processing reliability with built-in retry mechanisms",
                "Reduced operational costs by 60% compared to traditional VM-based solutions",
                "Scaled automatically to handle 5x normal load during peak periods",
                "Eliminated all infrastructure management overhead for the data team"
            ],
            successFactors: "Success was driven by embracing cloud-native serverless design principles, implementing proper error handling and monitoring, and focusing on event-driven architecture patterns."
        }
    },
    {
        id: 9,
        title: "Secure and Compliant Azure Environment",
        shortDescription: "Architected and implemented a comprehensive security strategy for an Azure environment using Azure Security Center, Azure Policy, and advanced identity protection.",
        challenge: "An organization in a regulated industry needed to establish a secure Azure environment that meets strict compliance requirements while enabling innovation and agility.",
        solution: "Designed and implemented a defense-in-depth security strategy leveraging Azure Security Center, Azure Policy, Azure AD with MFA, Azure Key Vault, and network security controls to create a secure and compliant foundation.",
        technologies: ["Azure Security Center", "Azure Policy", "Azure Active Directory", "Azure Key Vault", "Azure Firewall", "Network Security Groups"],
        category: "security",
        color: "#d13438",
        icon: <SecurityIcon sx={{ fontSize: 60, color: "white" }} />,
        architecture: {
            description: "The solution implements multiple layers of security controls across identity, network, compute, and data, providing comprehensive protection in alignment with regulatory requirements.",
            keyElements: [
                "Azure Security Center for continuous threat detection and security posture management",
                "Azure Policy for automated compliance enforcement and governance",
                "Azure AD with MFA and Conditional Access for identity protection",
                "Azure Key Vault for centralized secrets and key management",
                "Network security with Azure Firewall and NSGs"
            ],
            services: ["Azure Security Center", "Azure Policy", "Azure Active Directory", "Azure Key Vault", "Azure Firewall", "Network Security Groups", "Azure Sentinel"]
        },
        implementation: {
            description: "Implemented a comprehensive security strategy that balanced protection requirements with operational needs, using automation to enforce consistent security controls.",
            steps: [
                {
                    title: "Security Baseline Definition",
                    description: "Established security baselines aligned with industry best practices and compliance requirements"
                },
                {
                    title: "Identity Implementation",
                    description: "Configured Azure AD with MFA, Conditional Access, and PIM for secure access"
                },
                {
                    title: "Policy Configuration",
                    description: "Implemented Azure Policy for automated compliance enforcement"
                },
                {
                    title: "Network Security",
                    description: "Deployed Azure Firewall and NSGs to control and monitor network traffic"
                },
                {
                    title: "Monitoring & Response",
                    description: "Established security monitoring and incident response procedures with Azure Sentinel"
                }
            ],
            challenges: "Overcame the challenge of balancing security requirements with operational flexibility by implementing a risk-based approach. Addressed complexity in security monitoring by centralizing logs and implementing automated alerts for critical issues."
        },
        results: {
            description: "The security implementation enabled the organization to achieve compliance while maintaining the agility and innovation benefits of cloud adoption.",
            metrics: [
                "Achieved 100% compliance with regulatory requirements in security audits",
                "Reduced security-related incidents by 85% through proactive controls",
                "Decreased time to detect security events from days to minutes",
                "Automated 95% of compliance checks, reducing manual effort",
                "Implemented consistent security controls across all Azure subscriptions"
            ],
            successFactors: "Success was achieved through a comprehensive approach to security that addressed people, process, and technology aspects, with security embedded throughout the cloud adoption lifecycle."
        }
    },
    {
        id: 10,
        title: "Modern Data Warehouse Solution",
        shortDescription: "Architected a comprehensive data warehouse solution using Azure Synapse Analytics and Power BI, enabling advanced analytics and business intelligence capabilities.",
        challenge: "An organization needed to consolidate disparate data sources into a unified analytics platform to enable data-driven decision making and gain competitive insights from their data assets.",
        solution: "Designed a modern data warehouse architecture using Azure Synapse Analytics with integration from multiple data sources, transformations through Azure Data Factory, and visualization via Power BI dashboards.",
        technologies: ["Azure Synapse Analytics", "Azure Data Factory", "Power BI", "Azure Data Lake Storage Gen2", "SQL Pools", "Spark Pools"],
        category: "data",
        color: "#ffb900",
        icon: <StorageIcon sx={{ fontSize: 60, color: "white" }} />,
        architecture: {
            description: "The solution provides a scalable, high-performance analytics platform that integrates data from various sources into a unified data warehouse with advanced analytics capabilities.",
            keyElements: [
                "Azure Synapse Analytics for enterprise-scale data warehousing",
                "Azure Data Factory for orchestrating ETL/ELT workflows",
                "Azure Data Lake Storage Gen2 for storing structured and unstructured data",
                "Power BI for interactive dashboards and reports",
                "SQL and Spark pools for different processing needs"
            ],
            services: ["Azure Synapse Analytics", "Azure Data Factory", "Power BI", "Azure Data Lake Storage Gen2", "Azure Key Vault"]
        },
        implementation: {
            description: "Implemented a modern data platform that consolidated data from multiple source systems into a unified analytics environment, enabling self-service BI and advanced analytics.",
            steps: [
                {
                    title: "Data Architecture Design",
                    description: "Designed the overall data architecture including storage, processing, and consumption layers"
                },
                {
                    title: "Data Lake Implementation",
                    description: "Established Azure Data Lake Storage Gen2 with appropriate folder structure and access controls"
                },
                {
                    title: "ETL Pipeline Development",
                    description: "Created data pipelines in Azure Data Factory for data extraction, transformation, and loading"
                },
                {
                    title: "Synapse Workspace Setup",
                    description: "Configured Synapse Analytics with appropriate SQL and Spark pools for different workloads"
                },
                {
                    title: "Dashboard Development",
                    description: "Developed interactive Power BI dashboards with drill-down capabilities"
                }
            ],
            challenges: "Overcame data integration challenges by implementing a medallion architecture (bronze, silver, gold) for progressive data refinement. Addressed performance issues by optimizing SQL queries and implementing materialized views for frequently accessed data."
        },
        results: {
            description: "The modern data warehouse enabled the organization to transform raw data into actionable insights, driving significant business value across multiple departments.",
            metrics: [
                "Consolidated data from 12+ source systems into a unified analytics platform",
                "Reduced time to generate business reports from days to minutes",
                "Enabled self-service analytics for 200+ business users",
                "Processed over 5TB of data daily with enterprise-grade performance",
                "Uncovered business insights that generated $2M in additional revenue"
            ],
            successFactors: "Success was achieved through close collaboration with business stakeholders, iterative development approach, and a focus on delivering business value rather than just technical implementation."
        }
    },
    {
        id: 11,
        title: "Enterprise DevOps Implementation",
        shortDescription: "Built a comprehensive CI/CD pipeline for microservices on Azure Kubernetes Service, enabling rapid and reliable software delivery across the organization.",
        challenge: "A large enterprise needed to modernize their software delivery processes to increase release velocity, improve quality, and standardize deployment practices across multiple development teams.",
        solution: "Designed and implemented an end-to-end DevOps platform using Azure DevOps, Azure Container Registry, and Azure Kubernetes Service, enabling automated testing, building, and deployment of containerized applications.",
        technologies: ["Azure DevOps", "Azure Kubernetes Service", "Azure Container Registry", "Docker", "Helm", "Infrastructure as Code", "ARM Templates"],
        category: "devops",
        color: "#7FBA00",
        icon: <EngineeringIcon sx={{ fontSize: 60, color: "white" }} />,
        architecture: {
            description: "The solution provides a complete CI/CD platform for containerized applications with automated pipelines, security scanning, and deployment to Kubernetes environments.",
            keyElements: [
                "Azure DevOps for source control, build, test, and release pipelines",
                "Azure Container Registry for secure storage of container images",
                "Azure Kubernetes Service for container orchestration",
                "Infrastructure as Code for consistent environment provisioning",
                "Automated security scanning and quality gates"
            ],
            services: ["Azure DevOps", "Azure Kubernetes Service", "Azure Container Registry", "Azure Key Vault", "Azure Monitor", "Application Insights"]
        },
        implementation: {
            description: "Implemented a standardized yet flexible DevOps platform that enabled teams to focus on application development while automated pipelines handled the build, test, and deployment processes.",
            steps: [
                {
                    title: "DevOps Strategy Definition",
                    description: "Defined the overall DevOps strategy, toolchain, and practices aligned with business goals"
                },
                {
                    title: "Pipeline Development",
                    description: "Created standardized CI/CD pipeline templates with security scanning and quality gates"
                },
                {
                    title: "AKS Implementation",
                    description: "Deployed and configured Azure Kubernetes Service clusters with proper security and scaling"
                },
                {
                    title: "Container Registry Setup",
                    description: "Established Azure Container Registry with vulnerability scanning and image policies"
                },
                {
                    title: "Monitoring Implementation",
                    description: "Implemented comprehensive monitoring and alerting for applications and infrastructure"
                }
            ],
            challenges: "Overcame cultural resistance by implementing a comprehensive training program and showcasing early wins. Addressed technical challenges by creating reusable pipeline templates that balanced standardization with team-specific flexibility."
        },
        results: {
            description: "The DevOps implementation transformed the organization's software delivery capabilities, enabling faster releases with higher quality and improved operational efficiency.",
            metrics: [
                "Reduced deployment time from days to minutes with automated pipelines",
                "Increased deployment frequency from monthly to multiple times per day",
                "Decreased production incidents by 75% through automated testing and quality gates",
                "Improved developer productivity by eliminating manual deployment tasks",
                "Standardized deployment practices across 15+ development teams"
            ],
            successFactors: "Success was driven by focusing on people and process aspects alongside technology implementation, creating a true DevOps culture change rather than just implementing tools."
        }
    },
    {
        id: 12,
        title: "Hybrid Kubernetes with Azure Arc",
        shortDescription: "Implemented a unified management plane for on-premises and cloud Kubernetes clusters using Azure Arc, enabling consistent operations and governance.",
        challenge: "An organization with both on-premises and cloud-based Kubernetes clusters needed a consistent way to manage, govern, and secure their container environments across multiple locations.",
        solution: "Leveraged Azure Arc to extend Azure management capabilities to on-premises Kubernetes clusters, providing unified operations, governance, and security across hybrid infrastructure.",
        technologies: ["Azure Arc", "Kubernetes", "Azure Policy", "Azure Monitor", "GitOps", "Infrastructure as Code"],
        category: "hybrid",
        color: "#0078d4",
        icon: <ArchitectureIcon sx={{ fontSize: 60, color: "white" }} />,
        architecture: {
            description: "The solution extends Azure's management plane to on-premises Kubernetes clusters, enabling consistent operations and governance across hybrid environments.",
            keyElements: [
                "Azure Arc for extending Azure management to on-premises Kubernetes",
                "GitOps for configuration management and application deployment",
                "Azure Policy for consistent governance across environments",
                "Azure Monitor for unified observability",
                "RBAC for standardized access control"
            ],
            services: ["Azure Arc", "Azure Kubernetes Service", "Azure Policy", "Azure Monitor", "Azure Security Center"]
        },
        implementation: {
            description: "Implemented Azure Arc to connect on-premises Kubernetes clusters to Azure, enabling consistent management, governance, and operations across hybrid environments.",
            steps: [
                {
                    title: "Arc Agent Deployment",
                    description: "Deployed Azure Arc agents to connect on-premises Kubernetes clusters to Azure"
                },
                {
                    title: "GitOps Configuration",
                    description: "Implemented GitOps workflows for configuration management and application deployment"
                },
                {
                    title: "Policy Implementation",
                    description: "Applied Azure Policy to enforce consistent security and compliance across clusters"
                },
                {
                    title: "Monitoring Integration",
                    description: "Set up Azure Monitor for comprehensive visibility across all Kubernetes environments"
                },
                {
                    title: "Access Control Configuration",
                    description: "Established consistent RBAC model across hybrid Kubernetes estate"
                }
            ],
            challenges: "Overcame network connectivity challenges by implementing proper proxy configurations and addressing firewall requirements. Managed existing Kubernetes customizations by carefully planning the Arc integration to avoid disrupting critical workloads."
        },
        results: {
            description: "The hybrid Kubernetes implementation enabled the organization to maintain existing investments while gaining the benefits of cloud-based management and governance.",
            metrics: [
                "Unified management of 20+ Kubernetes clusters across multiple environments",
                "Reduced operational overhead by 50% through consistent management tools",
                "Implemented consistent security policies across all container environments",
                "Decreased mean time to resolution for issues by 60% through centralized monitoring",
                "Enabled cloud-native deployment patterns for on-premises workloads"
            ],
            successFactors: "Success was achieved by carefully planning the Arc integration, providing comprehensive training for operations teams, and implementing a phased approach to minimize disruption."
        }
    }
];

export default getProjectsData;