// src/components/home/SkillsHighlight.jsx
import React from 'react';
import { Box, Container, Typography, Grid, Paper, LinearProgress, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const SkillsHighlight = () => {
  const coreSkills = [
    { name: 'Azure Cloud Architecture', level: 95, color: '#0078d4' },
    { name: 'Cloud Security', level: 90, color: '#d13438' },
    { name: 'DevOps & CI/CD', level: 90, color: '#7FBA00' },
    { name: 'Infrastructure as Code', level: 85, color: '#ff8c00' },
    { name: 'Azure Kubernetes Service', level: 90, color: '#0078d4' },
    { name: 'Azure Active Directory', level: 90, color: '#0078d4' },
  ];
  
  return (
    <Box sx={{ py: 8, bgcolor: 'background.paper' }}>
      <Container maxWidth="lg">
        <Box sx={{ mb: 5, textAlign: 'center' }}>
          <Typography variant="h4" component="h2" gutterBottom fontWeight="bold">
            Technical Expertise
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" paragraph sx={{ maxWidth: '700px', mx: 'auto' }}>
            Specialized in Microsoft Azure cloud technologies with over 6+ years of hands-on experience
          </Typography>
        </Box>
        
        <Grid container spacing={3}>
          {coreSkills.map((skill, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Paper 
                elevation={0} 
                variant="outlined" 
                sx={{ 
                  p: 3, 
                  height: '100%',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: 2
                  }
                }}
              >
                <Box sx={{ mb: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    {skill.name}
                  </Typography>
                  <LinearProgress 
                    variant="determinate" 
                    value={skill.level} 
                    sx={{ 
                      height: 8, 
                      borderRadius: 5,
                      backgroundColor: 'rgba(0, 0, 0, 0.08)',
                      '& .MuiLinearProgress-bar': {
                        borderRadius: 5,
                        backgroundColor: skill.color
                      }
                    }} 
                  />
                </Box>
                
                <Typography variant="body2" color="text.secondary">
                  {skill.level >= 90 
                    ? 'Expert-level proficiency with extensive production experience' 
                    : 'Advanced proficiency with solid implementation experience'}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
        
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button 
            component={RouterLink} 
            to="/skills" 
            variant="outlined"
            size="large"
            sx={{ px: 3 }}
          >
            View All Skills
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default SkillsHighlight;