// src/pages/SkillsPage.jsx
import React from 'react';
import Skills from '../components/skills/Skills';
import { Box, Typography, Container } from '@mui/material';

const SkillsPage = () => {
  return (
    <>
      <Box 
        sx={{ 
          py: 6,
          background: 'linear-gradient(rgba(0, 30, 60, 0.9), rgba(0, 30, 60, 0.8))',
          mb: 4
        }}
      >
        <Container maxWidth="lg">
          <Typography variant="h3" component="h1" align="center" color="white" gutterBottom fontWeight="bold">
            Technical Skills & Expertise
          </Typography>
          <Typography variant="subtitle1" align="center" color="white" paragraph>
            Comprehensive Azure cloud and IT skills developed over 11+ years of professional experience
          </Typography>
        </Container>
      </Box>
      <Skills />
    </>
  );
};

export default SkillsPage;