import React from 'react';
import { 
  Box, 
  Typography, 
  Container, 
  Card, 
  CardContent, 
  Chip, 
  Grid,
  Divider
} from '@mui/material';

const AdditionalProjects = () => {
  const additionalProjects = [
    {
      id: 101,
      title: "Enterprise-Wide Exchange Migration",
      shortDescription: "Migrated from on-premises Exchange 2016 to Office 365 using a hybrid infrastructure approach, ensuring seamless mail flow and minimal user disruption.",
      technologies: ["Exchange Online", "Azure AD Connect", "Office 365", "Hybrid Configuration Wizard", "PowerShell"]
    },
    {
      id: 102,
      title: "Active Directory & Exchange Migration",
      shortDescription: "Led the merger of two company's Active Directory and Exchange environments, consolidating domains and ensuring proper mail routing and security.",
      technologies: ["Active Directory", "Exchange Server", "ADMT", "PowerShell", "Azure AD"]
    },
    {
      id: 103,
      title: "Proofpoint Messaging Gateway Migration",
      shortDescription: "Implemented and migrated to Proofpoint email security gateway, enhancing protection against phishing, spam, and advanced email threats.",
      technologies: ["Proofpoint", "Email Security", "SMTP", "PowerShell", "Azure Network Security"]
    },
    {
      id: 104,
      title: "Multi-Tenant Azure Landing Zone Integration",
      shortDescription: "Consolidated multiple Azure tenants into a primary landing zone with secure connections from on-premises infrastructure via IPsec VPN.",
      technologies: ["Azure Landing Zones", "IPsec VPN", "Juniper", "Azure VPNGW", "Hub-Spoke Architecture"]
    },
    {
      id: 105,
      title: "Exchange 2013 Multi-DAG Infrastructure",
      shortDescription: "Designed and deployed a highly available Microsoft Exchange 2013 infrastructure with multiple Database Availability Groups for enhanced resilience.",
      technologies: ["Exchange 2013", "DAG", "Windows Server", "Load Balancing", "PowerShell"]
    },
    {
      id: 106,
      title: "Dual Azure Landing Zone Implementation",
      shortDescription: "Created secure Azure landing zones for both Commercial and Government clouds with integrated Active Directory upgrade and migration.",
      technologies: ["Azure Landing Zones", "Azure Policy", "Azure Blueprints", "Active Directory", "Identity Management"]
    },
    {
      id: 107,
      title: "Endpoint Security Automation",
      shortDescription: "Deployed CrowdStrike endpoint security sensors at scale using Azure Guest Configuration for automated and consistent implementation.",
      technologies: ["CrowdStrike", "Azure Guest Configuration", "Azure Policy", "PowerShell DSC", "Azure Automation"]
    },
    {
      id: 108,
      title: "Comprehensive Azure Security Implementation",
      shortDescription: "Implemented an end-to-end Azure security stack including identity management, network security, data protection, and monitoring solutions.",
      technologies: ["Azure AD", "Key Vault", "Network Security Groups", "Azure DDoS Protection", "Azure Security Center", "Azure Monitor", "Azure Disk Encryption", "AD PIM", "Azure Information Protection", "Azure Firewall"]
    }
  ];

  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 6 }}>
        <Typography variant="h4" component="h2" gutterBottom align="center" fontWeight="bold">
          Additional Azure & Infrastructure Projects
        </Typography>
        <Typography variant="subtitle1" align="center" paragraph color="text.secondary" sx={{ mb: 5 }}>
          Enterprise infrastructure and security implementations
        </Typography>
        
        <Grid container spacing={3}>
          {additionalProjects.map((project) => (
            <Grid item xs={12} sm={6} key={project.id}>
              <Card sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
                }
              }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h6" component="h3" gutterBottom>
                    {project.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    {project.shortDescription}
                  </Typography>
                  
                  <Divider sx={{ my: 2 }} />
                  
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {project.technologies.map((tech, index) => (
                      <Chip 
                        key={index} 
                        label={tech} 
                        size="small" 
                        sx={{ 
                          mb: 1,
                          bgcolor: 'background.paper', 
                          border: '1px solid',
                          borderColor: 'primary.light'
                        }} 
                      />
                    ))}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default AdditionalProjects;