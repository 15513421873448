// src/pages/AboutPage.jsx
import React from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
  Paper,
  Divider,
  Chip,
  Avatar,
  Button
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DownloadIcon from '@mui/icons-material/Download';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import SecurityIcon from '@mui/icons-material/Security';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { Link as RouterLink } from 'react-router-dom';

const AboutPage = () => {
  // Core competencies
  const coreCompetencies = [
    {
      title: 'Azure Architecture',
      icon: <ArchitectureIcon />,
      description: 'Designing secure, scalable, and cost-effective cloud solutions following the Azure Well-Architected Framework'
    },
    {
      title: 'Cloud Security',
      icon: <SecurityIcon />,
      description: 'Implementing Zero Trust architecture and defense-in-depth security controls across Azure environments'
    },
    {
      title: 'DevOps & Automation',
      icon: <EngineeringIcon />,
      description: 'Establishing CI/CD pipelines, Infrastructure as Code, and automation practices for cloud resources'
    },
    {
      title: 'Azure Solutions',
      icon: <CloudDoneIcon />,
      description: "Building comprehensive cloud and hybrid solutions leveraging Azure's platform capabilities"
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Grid container spacing={6}>
        {/* Main Content Section */}
        <Grid item xs={12} md={8}>
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            fontWeight="bold"
            sx={{
              mb: 4,
              position: 'relative',
              '&:after': {
                content: '""',
                position: 'absolute',
                width: '80px',
                height: '4px',
                bottom: '-10px',
                left: 0,
                backgroundColor: 'primary.main'
              }
            }}
          >
            About Me
          </Typography>

          <Box sx={{ mb: 6 }}>
            <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.7 }}>
              A results-oriented technology leader based in Texas, <strong>Leonard Esere</strong> brings over 11 years of experience in the tech industry, with the last 6 years deeply focused on the Microsoft Azure ecosystem. As a seasoned Azure Engineer and Architect, Leonard possesses a comprehensive understanding of cloud and hybrid solutions, specializing in designing and implementing secure, scalable, and highly available infrastructures. His expertise spans across compute, network, storage, monitoring, and security, aligning with the Azure Well-Architected Framework and Cloud Adoption Framework for Azure.
            </Typography>

            <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.7 }}>
              Leonard excels at translating business requirements into robust Azure architectures, collaborating effectively with stakeholders across development, administration, security, and data engineering teams. His experience encompasses the full lifecycle of cloud projects, from initial design and planning to deployment, automation, and ongoing optimization. He is adept at leveraging Infrastructure as Code (IaC) practices and implementing CI/CD pipelines to drive efficiency and agility.
            </Typography>

            <Typography variant="body1" sx={{ fontSize: '1.1rem', lineHeight: 1.7 }}>
              With a strong foundation in both Azure administration and development, Leonard is passionate about enabling organizations to achieve their strategic objectives through innovative and well-architected cloud solutions. His commitment to continuous learning and staying at the forefront of Azure technologies ensures that he can provide expert guidance and deliver impactful results.
            </Typography>
          </Box>

          <Divider sx={{ mb: 6 }} />

          <Typography variant="h4" component="h2" gutterBottom fontWeight="bold" sx={{ mb: 4 }}>
            Core Competencies
          </Typography>

          <Grid container spacing={3} sx={{ mb: 6 }}>
            {coreCompetencies.map((competency, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Paper
                  elevation={0}
                  variant="outlined"
                  sx={{
                    p: 3,
                    height: '100%',
                    borderRadius: 2,
                    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-5px)',
                      boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)'
                    }
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Box
                      sx={{
                        mr: 2,
                        color: 'primary.main',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '2rem'
                      }}
                    >
                      {competency.icon}
                    </Box>
                    <Typography variant="h6" fontWeight="bold">
                      {competency.title}
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    {competency.description}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2 }}>
            <Button
              variant="contained"
              component={RouterLink}
              to="/projects"
              endIcon={<ArrowForwardIcon />}
              size="large"
            >
              View My Projects
            </Button>
            <Button
              variant="outlined"
              startIcon={<DownloadIcon />}
              size="large"
              component="a"
              href="/Leonard AEsere.pdf"
              download
              target="_blank"
              rel="noopener noreferrer"
            >
              Download Resume
            </Button>
          </Box>
        </Grid>

        {/* Sidebar Section */}
        <Grid item xs={12} md={4}>
          <Paper
            elevation={0}
            variant="outlined"
            sx={{
              p: 4,
              borderRadius: 2,
              position: 'sticky',
              top: '100px',
            }}
          >
            <Box sx={{ mb: 4, textAlign: 'center' }}>
              <Avatar
                sx={{
                  width: 150,
                  height: 150,
                  margin: '0 auto',
                  mb: 2,
                  border: '4px solid',
                  borderColor: 'primary.main'
                }}
                alt="Leonard Esere"
                src="/bgsite81.JPG"  // Update with your profile image path
              />
              <Typography variant="h5" fontWeight="bold">Leonard Esere</Typography>
              <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                Azure Solutions Architect
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Based in Texas, United States
              </Typography>
            </Box>

            <Divider sx={{ mb: 3 }} />

            <Typography variant="h6" gutterBottom fontWeight="bold">
              Azure Expertise
            </Typography>

            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 3 }}>
              {[
                'Architecture Design', 'Security & Compliance', 'IaC', 'DevOps',
                'Networking', 'Identity & Access', 'Monitoring', 'Cost Optimization'
              ].map((skill) => (
                <Chip
                  key={skill}
                  label={skill}
                  size="small"
                  sx={{ mb: 1 }}
                />
              ))}
            </Box>

            <Typography variant="h6" gutterBottom fontWeight="bold">
              Certifications
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {[
                'Azure Solutions Architect Expert',
                'Azure DevOps Engineer Expert',
                'Azure Security Engineer Associate',
                'Azure Administrator Associate',
                'Azure Developer Associate'
              ].map((certification) => (
                <Box
                  key={certification}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    mb: 1
                  }}
                >
                  <CloudDoneIcon color="primary" fontSize="small" />
                  <Typography variant="body2">
                    {certification}
                  </Typography>
                </Box>
              ))}
            </Box>

            <Box sx={{ mt: 4 }}>
              <Button
                variant="outlined"
                component={RouterLink}
                to="/contact"
                fullWidth
              >
                Get In Touch
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutPage;