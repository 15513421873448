import React from 'react';
import { Box, Typography, Button, Container, Grid, Paper } from '@mui/material';
import Typewriter from 'typewriter-effect';
import { Link as RouterLink } from 'react-router-dom';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import SecurityIcon from '@mui/icons-material/Security';
import CodeIcon from '@mui/icons-material/Code';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';

const Hero = () => {
  return (
    <Box 
      sx={{ 
        background: 'linear-gradient(rgba(0, 30, 60, 0.9), rgba(0, 30, 60, 0.8)), url(/api/placeholder/1200/500)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        pt: 10, 
        pb: 10,
        borderRadius: 2,
        mb: 6,
        color: 'white',
      }}
    >
      <Container>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={7}>
            <Typography variant="h6" component="div" gutterBottom color="primary.light">
              CLOUD ENGINEER & ARCHITECT
            </Typography>
            <Typography variant="h2" component="h1" fontWeight="bold" gutterBottom>
              Leonard Esere
            </Typography>
            <Box sx={{ mb: 3, height: '80px' }}>
              <Typography variant="h4" component="div">
                <Typewriter
                  options={{
                    strings: [
                      'Azure Solutions Architect',
                      'DevOps Engineer Engineer',
                      'Cloud Security Engineer/Architect',
                      'AI Integration Engineer'
                    ],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </Typography>
            </Box>
            <Typography variant="body1" paragraph sx={{ mb: 4, maxWidth: '90%', fontSize: '1.1rem' }}>
              With over 11 years in IT and 6+ years of specialized Azure cloud experience, I architect
              and deliver robust, secure, and scalable cloud solutions that drive business transformation.
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button 
                variant="contained" 
                size="large" 
                component={RouterLink} 
                to="/projects" 
                sx={{ 
                  px: 4, 
                  py: 1.5,
                  fontSize: '1rem',
                  background: 'linear-gradient(45deg, #0078d4 30%, #50a8ff 90%)',
                }}
              >
                View Projects
              </Button>
              <Button 
                variant="outlined" 
                size="large" 
                component={RouterLink} 
                to="/contact"
                sx={{ 
                  px: 4, 
                  py: 1.5,
                  fontSize: '1rem',
                  borderColor: 'white',
                  color: 'white',
                  '&:hover': {
                    borderColor: 'primary.light',
                    backgroundColor: 'rgba(255,255,255,0.05)'
                  }
                }}
              >
                Contact Me
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Grid container spacing={2}>
              {[
                { icon: <CloudDoneIcon sx={{ fontSize: 40 }} />, text: 'Azure Cloud Solutions', color: '#0078d4' },
                { icon: <SecurityIcon sx={{ fontSize: 40 }} />, text: 'Cloud Security', color: '#7FBA00' },
                { icon: <CodeIcon sx={{ fontSize: 40 }} />, text: 'Infrastructure as Code', color: '#7719aa' },
                { icon: <AutoGraphIcon sx={{ fontSize: 40 }} />, text: 'AI & ML Integration', color: '#ffb900' }
                // { icon: <AutoGraphIcon sx={{ fontSize: 40 }} />, text: 'Data Solutions', color: '#ffb900' }
                // { icon: <AutoGraphIcon sx={{ fontSize: 40 }} />, text: '', color: '#ffb900' }
              ].map((item, index) => (
                <Grid item xs={6} key={index}>
                  <Paper
                    elevation={4}
                    sx={{
                      p: 3,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      backgroundColor: 'rgba(30, 30, 30, 0.8)',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: '0 12px 20px rgba(0, 0, 0, 0.2)',
                      }
                    }}
                  >
                    <Box sx={{ color: item.color, mb: 1 }}>
                      {item.icon}
                    </Box>
                    <Typography variant="subtitle1" component="div" fontWeight="medium">
                      {item.text}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Hero;