import React from 'react';
import { Box, Container, Typography, IconButton, Stack, Tooltip } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import CodeIcon from '@mui/icons-material/Code'; // For freeCodeCamp
import SchoolIcon from '@mui/icons-material/School'; // For Microsoft Learn

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: 'background.paper',
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
          <Typography variant="body2" color="text.secondary">
            © {new Date().getFullYear()} Leonard Esere - Cloud Engineer & Architect
          </Typography>
          
          <Stack direction="row" spacing={1}>
            <Tooltip title="LinkedIn">
              <IconButton 
                color="inherit" 
                aria-label="LinkedIn" 
                component="a" 
                href="https://www.linkedin.com/in/leonardesere/" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </IconButton>
            </Tooltip>
            
            <Tooltip title="GitHub">
              <IconButton 
                color="inherit" 
                aria-label="GitHub" 
                component="a" 
                href="https://github.com/NijTeck" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <GitHubIcon />
              </IconButton>
            </Tooltip>
            
            <Tooltip title="freeCodeCamp">
              <IconButton 
                color="inherit" 
                aria-label="freeCodeCamp" 
                component="a" 
                href="https://www.freecodecamp.org/regedited" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <CodeIcon />
              </IconButton>
            </Tooltip>
            
            <Tooltip title="Microsoft Learn">
              <IconButton 
                color="inherit" 
                aria-label="Microsoft Learn" 
                component="a" 
                href="https://learn.microsoft.com/en-us/users/leonardesere/" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <SchoolIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;