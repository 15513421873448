// src/components/skills/Skills.jsx
import React from 'react';
import { Box, Typography, Container, Grid, Paper, LinearProgress, Divider } from '@mui/material';

const SkillCategory = ({ title, skills }) => (
  <Box sx={{ mb: 4 }}>
    <Typography variant="h6" gutterBottom color="primary">
      {title}
    </Typography>
    <Grid container spacing={3}>
      {skills.map((skill, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Paper 
            sx={{ 
              p: 2, 
              height: '100%',
              '&:hover': {
                boxShadow: 6,
                transform: 'translateY(-3px)',
                transition: 'all 0.2s'
              } 
            }}
          >
            <Typography variant="subtitle1" gutterBottom>
              {skill.name}
            </Typography>
            <LinearProgress 
              variant="determinate" 
              value={skill.level} 
              sx={{ 
                mb: 1, 
                height: 8, 
                borderRadius: 5,
                backgroundColor: 'rgba(0, 120, 212, 0.2)',
                '& .MuiLinearProgress-bar': {
                  borderRadius: 5,
                  backgroundColor: skill.level >= 90 ? '#7FBA00' : '#0078d4'
                }
              }} 
            />
            <Typography variant="body2" color="text.secondary">
              {skill.description}
            </Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  </Box>
);

const Skills = () => {
  const skillsData = {
    core: [
      { name: 'Azure Cloud Architecture', level: 95, description: 'Expert-level design and implementation of comprehensive Azure solutions' },
      { name: 'Cloud Security', level: 90, description: 'Implementing robust security controls and compliance frameworks' },
      { name: 'DevOps & CI/CD', level: 90, description: 'Building automated pipelines for continuous integration and delivery' },
      { name: 'Infrastructure as Code', level: 85, description: 'ARM Templates, Bicep, Terraform for automated deployments' },
      { name: 'AI/ML Integration', level: 80, description: 'Implementing Azure AI solutions and machine learning workloads' },
      { name: 'Hybrid Cloud Solutions', level: 85, description: 'Bridging on-premises systems with Azure cloud resources' }
    ],
    azureServices: [
      { name: 'Azure Virtual Machines', level: 95, description: 'Configuration, scaling, and management of VM workloads' },
      { name: 'Azure Kubernetes Service', level: 90, description: 'Orchestrating containerized applications at scale' },
      { name: 'Azure App Service', level: 90, description: 'Deploying and managing web applications' },
      { name: 'Azure SQL Database', level: 85, description: 'Designing and optimizing cloud database solutions' },
      { name: 'Azure Active Directory', level: 90, description: 'Identity management and access control' },
      { name: 'Azure Virtual Networks', level: 95, description: 'Secure network architectures and configurations' },
      { name: 'Azure Functions', level: 85, description: 'Serverless computing and event-driven architectures' },
      { name: 'Azure Monitor', level: 85, description: 'Comprehensive monitoring and alerting' },
      { name: 'Azure Logic Apps', level: 80, description: 'Workflow automation and integration services' },
      { name: 'Azure DevOps', level: 90, description: 'End-to-end DevOps toolchain implementation' },
      { name: 'Azure Synapse Analytics', level: 80, description: 'Big data analytics and warehousing solutions' },
      { name: 'Azure Security Center', level: 90, description: 'Security posture management and threat protection' }
    ],
    advanced: [
      { name: 'Azure OpenAI Service', level: 80, description: 'Implementing generative AI solutions' },
      { name: 'Azure Cognitive Services', level: 85, description: 'Integrating pre-built AI capabilities' },
      { name: 'Zero Trust Security', level: 85, description: 'Implementing modern security architectures' },
      { name: 'Azure Firewall & NSGs', level: 90, description: 'Network security implementation and management' },
      { name: 'Azure Private Link', level: 85, description: 'Secure private endpoint connections to Azure PaaS' },
      { name: 'Azure Cosmos DB', level: 80, description: 'Global scale NoSQL database implementations' }
    ]
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 6 }}>
        <Typography variant="h4" component="h2" gutterBottom align="center" fontWeight="bold">
          Technical Skills
        </Typography>
        <Typography variant="subtitle1" align="center" paragraph color="text.secondary" sx={{ mb: 5 }}>
          With over 11 years in IT and 6+ years focused on Azure, I've developed expertise across a wide range of cloud technologies
        </Typography>
        
        <SkillCategory title="Core Cloud Competencies" skills={skillsData.core} />
        
        <Divider sx={{ my: 5 }} />
        
        <SkillCategory title="Azure Services Expertise" skills={skillsData.azureServices} />
        
        <Divider sx={{ my: 5 }} />
        
        <SkillCategory title="Advanced Technologies" skills={skillsData.advanced} />
      </Box>
    </Container>
  );
};

export default Skills;