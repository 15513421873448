// src/components/home/CertificationsHighlight.jsx
import React from 'react';
import { Box, Container, Typography, Grid, Button, Card, CardContent, CardMedia, Stack, Chip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import VerifiedIcon from '@mui/icons-material/Verified';

const CertificationsHighlight = () => {
  const topCerts = [
    {
      name: "Azure Solutions Architect Expert",
      code: "AZ-305",
      bgColor: "#0078d4",
      skills: ["Solution Architecture", "Identity & Governance", "Infrastructure"]
    },
    {
      name: "DevOps Engineer Expert",
      code: "AZ-400",
      bgColor: "#7FBA00",
      skills: ["CI/CD Pipelines", "Infrastructure as Code", "Monitoring"]
    },
    {
      name: "Security Engineer Associate",
      code: "AZ-500",
      bgColor: "#d13438",
      skills: ["Identity Management", "Platform Protection", "Security Operations"]
    }
  ];
  
  return (
    <Box sx={{ py: 8, bgcolor: 'background.default' }}>
      <Container maxWidth="lg">
        <Box sx={{ mb: 5, textAlign: 'center' }}>
          <Typography variant="h4" component="h2" gutterBottom fontWeight="bold">
            Professional Certifications
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" paragraph sx={{ maxWidth: '700px', mx: 'auto' }}>
            Industry-recognized credentials validating my expertise in cloud technologies
          </Typography>
        </Box>
        
        <Grid container spacing={4} justifyContent="center">
          {topCerts.map((cert, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card 
                elevation={2}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 12px 20px rgba(0, 0, 0, 0.15)',
                  },
                }}
              >
                <CardMedia
                  component="div"
                  sx={{
                    height: 100,
                    bgcolor: cert.bgColor,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant="h6" color="white" fontWeight="bold" align="center" sx={{ p: 2 }}>
                    {cert.name}
                  </Typography>
                </CardMedia>
                <CardContent sx={{ flexGrow: 1, pt: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <VerifiedIcon color="primary" sx={{ mr: 1 }} />
                    <Typography variant="subtitle2" component="div" fontWeight="medium">
                      {cert.code}
                    </Typography>
                  </Box>
                  
                  <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', gap: 0.5 }}>
                    {cert.skills.map((skill, i) => (
                      <Chip 
                        key={i}
                        label={skill}
                        size="small"
                        sx={{ 
                          mt: 0.5,
                          bgcolor: 'background.paper', 
                          border: '1px solid',
                          borderColor: 'primary.light'
                        }}
                      />
                    ))}
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button 
            component={RouterLink} 
            to="/certifications" 
            variant="outlined"
            size="large"
            sx={{ px: 3 }}
          >
            View All Certifications
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default CertificationsHighlight;