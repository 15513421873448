// Updated src/components/projects/ProjectDetailDialog.jsx
import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Typography, 
  IconButton, 
  Box,
  Grid,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ProjectDetailDialog = ({ project, open, onClose }) => {
  if (!project) return null;

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="md" 
      fullWidth
      scroll="paper"
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5" component="div">{project.title}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ color: 'grey.500' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      
      <DialogContent dividers>
        <Grid container spacing={3}>
          {/* Project Overview */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>Project Overview</Typography>
            <Typography variant="body1" paragraph>
              {project.solution}
            </Typography>
            
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
              {project.technologies.map((tech, index) => (
                <Chip key={index} label={tech} size="small" />
              ))}
            </Box>
          </Grid>
          
          {/* Divider */}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          
          {/* Challenge & Architecture */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>Challenge</Typography>
            <Typography variant="body2" paragraph>
              {project.challenge}
            </Typography>
            
            <Typography variant="h6" gutterBottom>Architecture</Typography>
            <Typography variant="body2" paragraph>
              {project.architecture.description}
            </Typography>
            
            <Paper variant="outlined" sx={{ p: 2, bgcolor: 'background.paper' }}>
              <Typography variant="subtitle2" gutterBottom>Key Elements</Typography>
              <List dense disablePadding>
                {project.architecture.keyElements.map((element, index) => (
                  <ListItem key={index} disableGutters>
                    <ListItemText 
                      primary={element}
                      primaryTypographyProps={{ variant: 'body2' }}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
          
          {/* Implementation & Results */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>Implementation</Typography>
            <Typography variant="body2" paragraph>
              {project.implementation.description}
            </Typography>
            
            <Typography variant="h6" gutterBottom>Results</Typography>
            <Typography variant="body2" paragraph>
              {project.results.description}
            </Typography>
            
            <Paper variant="outlined" sx={{ p: 2, bgcolor: 'background.paper' }}>
              <Typography variant="subtitle2" gutterBottom>Key Metrics</Typography>
              <List dense disablePadding>
                {project.results.metrics.map((metric, index) => (
                  <ListItem key={index} disableGutters>
                    <ListItemText 
                      primary={metric}
                      primaryTypographyProps={{ variant: 'body2' }}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectDetailDialog;