// src/main.jsx
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
// Import App.css to ensure styling is applied
import './App.css'

// Temporarily remove StrictMode for debugging
// If issues persist after other fixes, put StrictMode back
ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
)