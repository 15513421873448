// src/theme.js
import { createTheme } from '@mui/material/styles';

// Create an extended theme with custom properties for MUI 6.x
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#0078d4', // Azure blue
      light: '#50a8ff',
      dark: '#004c8c',
    },
    secondary: {
      main: '#7FBA00', // Azure green
      light: '#b1eb33',
      dark: '#4d8a00',
    },
    background: {
      default: '#121212',
      paper: '#1E1E1E',
    },
    // Custom colors need to be defined properly for MUI 6.x
    // We replace the 'azure' object with proper custom color definitions
    azureBlue: {
      main: '#0078d4',
    },
    azureGreen: {
      main: '#7FBA00',
    },
    azurePurple: {
      main: '#7719aa',
    },
    azureRed: {
      main: '#d13438',
    },
    azureYellow: {
      main: '#ffb900',
    },
  },
  typography: {
    fontFamily: '"Segoe UI", "Roboto", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          textTransform: 'none',
          fontWeight: 600,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
        },
      },
    },
  },
});

export default theme;