// src/components/projects/Projects.jsx
import React, { useState } from 'react';
import ProjectDetailDialog from "./ProjectDetailDialog";
import { 
  Box, 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  Button 
} from '@mui/material';

// Import icons directly
import ArchitectureIcon from '@mui/icons-material/Architecture';
import EngineeringIcon from '@mui/icons-material/Engineering';
import SecurityIcon from '@mui/icons-material/Security';
import StorageIcon from '@mui/icons-material/Storage';
import CloudIcon from '@mui/icons-material/Cloud';
import DevicesIcon from '@mui/icons-material/Devices';

// Import the project data
import getProjectsData from '../../data/projectsData';

const Projects = () => {
  // State for filter
  const [filter, setFilter] = useState('all');
  
  // State for project detail dialog
  const [selectedProject, setSelectedProject] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  // Get projects data from the imported function
  const projectsData = getProjectsData();

  // Filter projects based on selected category
  const filteredProjects = filter === 'all' 
    ? projectsData 
    : projectsData.filter(project => project.category === filter);

  // Handle opening project details
  const handleOpenDetails = (project) => {
    setSelectedProject(project);
    setDialogOpen(true);
  };

  // Handle closing project details
  const handleCloseDetails = () => {
    setDialogOpen(false);
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 6 }}>
        <Typography variant="h4" component="h2" gutterBottom align="center" fontWeight="bold">
          Featured Projects
        </Typography>
        <Typography variant="subtitle1" align="center" paragraph color="text.secondary" sx={{ mb: 4 }}>
          Azure cloud solutions showcasing architecture, security, and innovation
        </Typography>
        
        <Grid container spacing={4}>
          {filteredProjects.map((project) => (
            <Grid item xs={12} sm={6} md={4} key={project.id}>
              <Card sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                bgcolor: 'background.paper',
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'translateY(-8px)',
                }
              }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h6" component="h3" gutterBottom>
                    {project.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    {project.shortDescription}
                  </Typography>
                  <Button 
                    variant="outlined" 
                    sx={{ mt: 'auto' }}
                    fullWidth
                    onClick={() => handleOpenDetails(project)}
                  >
                    View Details
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Project Detail Dialog */}
      <ProjectDetailDialog 
        project={selectedProject} 
        open={dialogOpen} 
        onClose={handleCloseDetails} 
      />
    </Container>
  );
};

export default Projects;