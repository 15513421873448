// src/components/common/NewsTicker.jsx
import React from 'react';
import { Box, Typography, Link, useTheme } from '@mui/material';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CloudIcon from '@mui/icons-material/Cloud';

const NewsTicker = () => {
  const theme = useTheme();
  
  const newsItems = [
    {
      text: "Microsoft announces new Azure OpenAI Service features for enterprise applications",
      source: "Microsoft Azure Blog",
      link: "https://azure.microsoft.com/en-us/blog/"
    },
    {
      text: "AWS introduces new EC2 instances powered by custom Arm-based Graviton processors",
      source: "AWS News Blog",
      link: "https://aws.amazon.com/blogs/aws/"
    },
    {
      text: "Google Cloud expands its sustainability efforts with Carbon Footprint reporting tools",
      source: "Google Cloud Blog",
      link: "https://cloud.google.com/blog/"
    },
    {
      text: "Azure's new Confidential Computing features enhance data security and privacy",
      source: "Microsoft Azure Blog",
      link: "https://azure.microsoft.com/en-us/blog/"
    },
    {
      text: "Kubernetes 1.28 released with new features for improved scalability and security",
      source: "Kubernetes Blog",
      link: "https://kubernetes.io/blog/"
    }
  ];

  const portfolioMessages = [
    {
      text: "Welcome to Leonard Esere's Azure Cloud Solutions Portfolio",
      source: "Portfolio",
      link: "#"
    },
    {
      text: "Specializing in Azure architecture, security, and cloud engineering",
      source: "Portfolio",
      link: "#"
    }
  ];

  const allMessages = [...portfolioMessages, ...newsItems];

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'primary.dark',
        color: 'white',
        overflow: 'hidden',
        position: 'relative',
        py: 1.25,
        borderBottom: '1px solid',
        borderColor: 'rgba(255, 255, 255, 0.1)'
      }}
    >
      {/* Static label */}
      <Box 
        sx={{ 
          position: 'absolute', 
          left: 16, 
          top: '50%', 
          transform: 'translateY(-50%)',
          display: 'flex',
          alignItems: 'center',
          zIndex: 2 // Ensure this stays on top
        }}
      >
        <CloudIcon fontSize="small" sx={{ mr: 1 }} />
        <Typography variant="body2">Cloud News</Typography>
      </Box>
      
      {/* Use CSS keyframes directly in style tag for better browser support */}
      <style>
        {`
          @keyframes ticker {
            0% {
              transform: translateX(100%);
            }
            100% {
              transform: translateX(-100%);
            }
          }

          .ticker-content {
            display: flex;
            white-space: nowrap;
            animation: ticker 100s linear infinite;
          }

          .ticker-container:hover .ticker-content {
            animation-play-state: paused;
          }
        `}
      </style>
      
      <Box
        className="ticker-container"
        sx={{
          width: '100%',
          overflow: 'hidden',
          paddingLeft: '120px' // Space for the fixed label
        }}
      >
        <Box
          className="ticker-content"
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {[...allMessages, ...allMessages].map((item, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                px: 3,
                '&:not(:last-child)::after': {
                  content: '""',
                  display: 'inline-block',
                  width: '8px',
                  height: '8px',
                  bgcolor: theme.palette.secondary.main,
                  borderRadius: '50%',
                  ml: 3
                }
              }}
            >
              <NewspaperIcon fontSize="small" sx={{ mr: 1, opacity: 0.8 }} />
              <Link
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  color: 'inherit',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                    color: theme.palette.secondary.light
                  }
                }}
              >
                <Typography variant="body2" component="span">
                  {item.text} 
                  <Typography 
                    component="span" 
                    variant="body2" 
                    sx={{ 
                      ml: 1,
                      opacity: 0.8, 
                      fontStyle: 'italic'
                    }}
                  >
                    [Source: {item.source}]
                  </Typography>
                </Typography>
              </Link>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default NewsTicker;